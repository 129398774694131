import { RectanglesPositions } from '@/components/common/LayoutWithLabel/types';

export const rectanglesPositions: RectanglesPositions = {
  desktop: [
    {
      x: -6,
      y: 31,
      width: 286,
      height: 21,
    },
    {
      x: 648,
      y: 31,
      width: 236,
      height: 21,
    },
    {
      x: 230,
      y: 120,
      width: 235,
      height: 14,
    },
    {
      x: 578,
      y: 135,
      width: 286,
      height: 21,
    },
    {
      x: -6,
      y: 266,
      width: 53,
      height: 19,
    },
    {
      x: 788,
      y: 285,
      width: 286,
      height: 21,
    },
    {
      x: 893,
      y: 453,
      width: 277,
      height: 21,
    },
    {
      x: 535,
      y: 456,
      width: 246,
      height: 21,
    },
  ],
  mobile: [
    { x: 3, y: 126, width: 257, height: 21 },
    { x: 234, y: 296, width: 362, height: 21 },
    { x: 100, y: 400, width: 286, height: 21 },
    { x: -70, y: 505, width: 270, height: 21 },
  ],
};
