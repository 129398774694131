
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import Benefits from './components/Benefits';
import { rectanglesPositions } from './components/RectanglesPositions';
import classes from './index.module.scss';
import TryOutForFreeImage from '@/images/home/tryOutForFreeImage.jpeg';
import LayoutWithLabel from '@/components/common/LayoutWithLabel';
import Button from '@/components/common/Button';
import { Section } from '@/components/common/Section';
import SectionBody from '@/components/common/SectionBody';
import ConfigsContext from '@/contexts/configs';
import { pushToDataLayer } from '@/helpers/googleTagManager';
const TryOutForFreeSection: React.FunctionComponent<React.PropsWithChildren> = () => {
    const { t } = useTranslation('home');
    const { features, benefits } = useContext(ConfigsContext);
    const { asPath } = useRouter();
    return (<Section backgroundColor={'white'} disableContentPaddingHorizontal disableBorderBottom contentPaddingBottomSettings={{
            xl: '0px',
        }}>
      <SectionBody main={<LayoutWithLabel layoutSettings={{
                withBackgroundRectangles: true,
            }} rectangles={rectanglesPositions} label={t('Try it for free')} image={{
                src: TryOutForFreeImage.src,
                alt: '',
                style: {
                    objectFit: 'cover',
                    objectPosition: '60% 50%',
                    opacity: 0.8,
                },
            }} heading={t('Create a business test account and get:')} main={<div className={classes.layout}>
                <Benefits data={benefits}/>
                <div className={classes.buttons}>
                  {features.isRegistrationEnabled && (<Button className={classes.button} href="/registration" label={t('Sign up free')} buttonType="callToAction" onClick={() => {
                        pushToDataLayer('btn_click', {
                            pagename: asPath,
                            button: 'registration',
                            component: 'Try it for free',
                        });
                    }}/>)}
                  {features.isIndividualOfferButtonVisible && (<Button className={classes.button} href="/individual-offer" label={t('common::Request an offer')} buttonType="primary" icon={{ iconName: 'arrow', placeAfterLabel: true }} onClick={() => {
                        pushToDataLayer('btn_click', {
                            pagename: asPath,
                            button: 'individual offer',
                            component: 'Try it for free',
                        });
                    }}/>)}
                </div>
              </div>}/>}/>
    </Section>);
};
export default TryOutForFreeSection;

    async function __Next_Translate__getStaticProps__1910ce41373__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//Home/components/TryOutForFree/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1910ce41373__ as getStaticProps }
  